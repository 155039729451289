import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { Crumbs } from 'containers';

import './Home.scss';

class Home extends Component {
	render() {
		return (
			<DocumentTitle title="Earth-i on AWS | Dashboard">
				<Container fluid>
					<Crumbs />
					<Row noGutters>
						<Col xs="12">
							<Card>
								<CardBody>Please make a selection from the menu options shown to the left</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = (data) => {
	return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
