import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import packageJson from '../../package.json';
import { getAuthenticatedUserAttributes } from '../actions';
import Logout from 'Logout';
import PasswordChange, { PasswordChangeElements } from 'PasswordChange';

import earthiLogo from 'images/earthi-rev.png';

export default class TopNav extends Component {
	state = {};

	async componentDidMount() {
		this.setState({
			...(await getAuthenticatedUserAttributes())
		});
	}

	render() {
		let { userHasAuthenticated, history } = this.props;

		return (
			<Fragment>
				<nav className="navbar navbar-expand navbar-dark bg-dark static-top">
					<NavLink className="navbar-brand mr-1" to="/home">
						<img src={earthiLogo} className="earthi-logo" alt="Earthi" />
					</NavLink>
					<button className="btn btn-link btn-sm text-white order-1 order-sm-0" id="sidebarToggle" href="#">
						<i className="fas fa-bars" />
						<span className="sr-only">Toggle side menu</span>
					</button>
					<div className="d-none d-md-inline-block ml-auto mr-0 mr-md-3 my-2 my-md-0" />
					<ul className="navbar-nav ml-auto ml-md-0">
						<li className="nav-item dropdown hidden-md-down my-2 my-lg-0">
							<button
								className="btn btn-primary"
								id="navbarAccountDropdownMenuLink"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{this.state.initials} <i className="fas fa-angle-down ml-2" />
							</button>
							<div
								className="dropdown-menu dropdown-menu-right"
								aria-labelledby="navbarAccountDropdownMenuLink"
							>
								<h6 className="dropdown-header">{this.state.email}</h6>
								<div className="dropdown-divider" />
								<PasswordChange />
								<Logout userHasAuthenticated={userHasAuthenticated} history={history} />
								<div className="dropdown-divider" />
								<p className="dropdown-header smaller text-center" style={{ padding: 0 }}>
									App version: {packageJson.version}
								</p>
							</div>
						</li>
					</ul>
				</nav>
				<PasswordChangeElements />
			</Fragment>
		);
	}
}
