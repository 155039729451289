const production = {
	apiGateway: {
		URL: 'https://aws-api.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_B4gywFuub',
		APP_CLIENT_ID: '2mkfsatp5lts76kogt666101m8',
		IDENTITY_POOL_ID: 'eu-west-2:d9145326-d4b3-4e72-959b-64cbedbf9738'
	},
	idleTimeout: 1000 * 60 * 30
};

const config = production;

const apiEndpoints = {
	servers: '/region/${regionId}/instances', // eslint-disable-line no-template-curly-in-string
	startServer: '/region/${regionId}/instance/${instanceId}/start', // eslint-disable-line no-template-curly-in-string
	stopServer: '/region/${regionId}/instance/${instanceId}/stop', // eslint-disable-line no-template-curly-in-string
	rebootServer: '/region/${regionId}/instance/${instanceId}/reboot', // eslint-disable-line no-template-curly-in-string
	terminateServer: '/region/${regionId}/instance/${instanceId}/terminate' // eslint-disable-line no-template-curly-in-string
};

export default {
	apiEndpoints,
	...config
};
