import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ForgotPassword, Home, Login, NotFound, SignUp, Servers } from 'screens';
import { AppliedRoute } from 'components';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Login} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute path="/signUp" exact component={SignUp} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute
			path="/forgotPassword"
			exact
			component={ForgotPassword}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute path="/home" exact component={Home} props={childProps} />
		<AppliedRoute path="/servers/:regionId?" exact component={Servers} props={childProps} />
		<Route component={NotFound} />
	</Switch>
);
