import React, { Component, Fragment } from 'react';
import { Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { Auth } from 'aws-amplify';

import $ from 'jquery';

import { LoaderButton } from 'components';

export default () => (
	<button type="button" className="dropdown-item pointered" data-toggle="modal" data-target="#changePasswordModal">
		<i className="fas fa-fw fa-lock mr-1" /> Password Change
	</button>
);

export class PasswordChangeElements extends Component {
	state = {
		passwordIsChanging: false,
		oldPassword: '',
		showOldPassword: false,
		newPassword1: '',
		showNewPassword1: false,
		newPassword2: '',
		showNewPassword2: false,
		passwordErrorMsg: ''
	};

	async componentDidMount() {
		$('.toast').toast({
			delay: 2500
		});
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	validatePasswordForm() {
		return (
			this.state.oldPassword.length > 0 &&
			this.state.newPassword1.length > 0 &&
			this.state.newPassword2.length > 0 &&
			this.state.newPassword1 === this.state.newPassword2
		);
	}

	handlePasswordChange = (event) => {
		event.preventDefault();
		this.setState({
			passwordIsChanging: true
		});

		const { oldPassword, newPassword1 } = this.state;

		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, oldPassword, newPassword1);
			})
			.then(() => {
				this.setState({
					passwordIsChanging: false,
					oldPassword: '',
					showOldPassword: false,
					newPassword1: '',
					showNewPassword1: false,
					newPassword2: '',
					showNewPassword2: false,
					passwordErrorMsg: ''
				});
				$('#changePasswordModal').modal('hide');
				$('#changePasswordToast').toast('show');
			})
			.catch(() => {
				this.setState({
					passwordIsChanging: false,
					passwordErrorMsg:
						'Your password change failed. Please check your existing password is correct and that your new password conforms to the required pattern.'
				});
			});
	};

	handlePasswordChangeCancel = (event) => {
		event.preventDefault();
		this.setState({
			passwordIsChanging: false,
			oldPassword: '',
			showOldPassword: false,
			newPassword1: '',
			showNewPassword1: false,
			newPassword2: '',
			showNewPassword2: false,
			passwordErrorMsg: ''
		});
		$('#changePasswordModal').modal('hide');
	};

	toggleShowOldPassword = () => this.setState({ showOldPassword: !this.state.showOldPassword });
	toggleShowNewPassword1 = () => this.setState({ showNewPassword1: !this.state.showNewPassword1 });
	toggleShowNewPassword2 = () => this.setState({ showNewPassword2: !this.state.showNewPassword2 });

	generatePasswordChangeModal() {
		return (
			<div
				className="modal fade"
				id="changePasswordModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="changePasswordModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-sm" role="document">
					<div className="modal-content">
						<Form onSubmit={this.handlePasswordChange}>
							<div className="modal-header">
								<h4 className="modal-title" id="changePasswordModalLabel">
									Password Change
								</h4>
								<button
									type="button"
									className="close"
									aria-label="Close"
									onClick={this.handlePasswordChangeCancel}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<FormGroup>
									<Label for="oldPassword">Existing password</Label>
									<InputGroup>
										<Input
											id="oldPassword"
											type={this.state.showOldPassword ? 'text' : 'password'}
											placeholder="Enter existing password"
											autoFocus
											value={this.state.oldPassword}
											onChange={this.handleChange}
										/>
										<InputGroupAddon addonType="append">
											<Button outline color="secondary" onClick={this.toggleShowOldPassword}>
												<i
													className={
														this.state.showOldPassword ? (
															'fa fa-eye fa-lg'
														) : (
															'fa fa-eye-slash fa-lg'
														)
													}
												/>
												<span className="sr-only">Toggle existing password display</span>
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</FormGroup>

								<FormGroup>
									<Label for="newPassword1">New password</Label>
									<InputGroup>
										<Input
											id="newPassword1"
											type={this.state.showNewPassword1 ? 'text' : 'password'}
											placeholder="Enter new password"
											value={this.state.newPassword1}
											onChange={this.handleChange}
										/>
										<InputGroupAddon addonType="append">
											<Button outline color="secondary" onClick={this.toggleShowNewPassword1}>
												<i
													className={
														this.state.showNewPassword1 ? (
															'fa fa-eye fa-lg'
														) : (
															'fa fa-eye-slash fa-lg'
														)
													}
												/>
												<span className="sr-only">Toggle new password 1 display</span>
											</Button>
										</InputGroupAddon>
									</InputGroup>
									<Label for="newPassword2" className="sr-only">
										Repeat new password
									</Label>
									<InputGroup className="mt-1">
										<Input
											id="newPassword2"
											type={this.state.showNewPassword2 ? 'text' : 'password'}
											placeholder="Repeat new password"
											value={this.state.newPassword2}
											onChange={this.handleChange}
										/>
										<InputGroupAddon addonType="append">
											<Button outline color="secondary" onClick={this.toggleShowNewPassword2}>
												<i
													className={
														this.state.showNewPassword2 ? (
															'fa fa-eye fa-lg'
														) : (
															'fa fa-eye-slash fa-lg'
														)
													}
												/>
												<span className="sr-only">Toggle new password 2 display</span>
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</FormGroup>
								<p className="help-block small text-muted font-italic mb-0">
									All fields are required. New passwords must match, should be more than 7 characters,
									include an uppercase letter, a lowercase letter, a special character and a number.
								</p>
								{this.state.passwordErrorMsg && (
									<Alert color="warning" className="mt-3 mb-0 pt-2 px-3 small">
										{this.state.passwordErrorMsg}
									</Alert>
								)}
							</div>
							<div className="modal-footer">
								<LoaderButton
									block
									color="primary"
									disabled={!this.validatePasswordForm()}
									type="submit"
									isLoading={this.state.passwordIsChanging}
									text="Change password"
									loadingText="Password change in progress…"
								/>
								<button
									type="button"
									className="btn btn-secondary"
									onClick={this.handlePasswordChangeCancel}
								>
									Cancel
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		);
	}

	generatePasswordChangeToast() {
		return (
			<div
				id="changePasswordToast"
				className="toast"
				role="alert"
				aria-live="polite"
				aria-atomic="true"
				style={{ position: 'absolute', top: '50px', right: '50px' }}
			>
				<div className="toast-header">
					<i className="fa fa-fw fa-info mr-2" />
					<strong className="mr-auto">Password Change</strong>
					<button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="toast-body">Your password has been successfully updated.</div>
			</div>
		);
	}

	render() {
		return (
			<Fragment>
				{this.generatePasswordChangeModal()}
				{this.generatePasswordChangeToast()}
			</Fragment>
		);
	}
}
