import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';

import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';

import { getAuthenticatedUserGroups } from 'actions';
import { LoaderButton, Terms } from 'components';

import './SignUp.scss';

import earthiLogo from 'images/earthi.svg';
import awsLogo from 'images/aws.svg';

export default class SignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			username: '',
			givenName: '',
			familyName: '',
			email: '',
			password: '',
			confirmPassword: '',
			confirmationCode: '',
			newUser: null,
			errorMsg: '',
			termsModal: false
		};

		this.toggleTermsModal = this.toggleTermsModal.bind(this);
	}

	toggleTermsModal() {
		this.setState({
			termsModal: !this.state.termsModal
		});
	}

	validateForm() {
		return (
			this.state.username.length > 0 &&
			this.state.givenName.length > 0 &&
			this.state.familyName.length > 0 &&
			this.state.email.length > 0 &&
			this.state.password.length > 0 &&
			this.state.password === this.state.confirmPassword
		);
	}

	validateConfirmationForm() {
		return this.state.confirmationCode.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	handleCheckboxChange = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
			errorMsg: ''
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			const newUser = await Auth.signUp({
				username: this.state.username,
				password: this.state.password,
				attributes: {
					given_name: this.state.givenName,
					family_name: this.state.familyName,
					email: this.state.email
				}
			});
			this.setState({
				newUser,
				isLoading: false
			});
		} catch ({ message: errorMsg }) {
			this.setState({
				errorMsg,
				isLoading: false
			});
		}
	};

	handleConfirmationSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});
		try {
			await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
			await Auth.signIn(this.state.username, this.state.password);
			const { authenticatedUserGroups: authGroups } = await getAuthenticatedUserGroups();
			this.props.userHasAuthenticated(true, authGroups);
			this.props.history.push('/home');
		} catch ({ message: errorMsg }) {
			this.setState({
				errorMsg,
				isLoading: false
			});
		}
	};

	renderConfirmationForm() {
		return (
			<Form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup>
					<Label for="confirmationCode">Verification code</Label>
					<Input
						id="confirmationCode"
						placeholder="Enter verification code"
						autoFocus
						value={this.state.confirmationCode}
						onChange={this.handleChange}
					/>
					<FormText color="muted">Please check your email for the verification code.</FormText>
				</FormGroup>
				{this.state.errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{this.state.errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Verify"
					loadingText="Verifying…"
				/>
			</Form>
		);
	}

	renderForm() {
		return (
			<Form onSubmit={this.handleSubmit}>
				<p>To sign up for INTaas please fill in your details. All fields are required.</p>
				<FormGroup>
					<Label for="username">Username</Label>
					<span className="help-block small font-italic text-muted ml-3">
						Username should not include spaces
					</span>
					<Input
						id="username"
						placeholder="Enter username"
						autoFocus
						value={this.state.username}
						onChange={this.handleChange}
					/>
				</FormGroup>

				<Row>
					<Col>
						<FormGroup>
							<Label for="givenName">First name</Label>
							<Input
								id="givenName"
								placeholder="Enter first name"
								value={this.state.givenName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="familyName">Last name</Label>
							<Input
								id="familyName"
								placeholder="Enter last name"
								value={this.state.familyName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="email">Email</Label>
					<Input id="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChange} />
				</FormGroup>
				<Row>
					<Col>
						<FormGroup>
							<Label for="password">Password</Label>
							<Input
								id="password"
								type="password"
								placeholder="Enter password"
								value={this.state.password}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="confirmPassword">Confirm password</Label>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="Enter password confirmation"
								value={this.state.confirmPassword}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<p className="help-block small text-muted font-italic">
					A password must be more than 7 characters and include an uppercase letter, a lowercase letter, a
					special character and a number
				</p>
				<Modal isOpen={this.state.termsModal} toggle={this.toggleTermsModal} fade size="lg">
					<ModalHeader toggle={this.toggleTermsModal}>
						Terms and Conditions for the Use of INTaas Service<br />
						<span className="smaller">Last updated 7th December 2018</span>
					</ModalHeader>
					<ModalBody className="small signup-modal">
						<Terms />
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggleTermsModal}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
				{this.state.errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{this.state.errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Sign up"
					loadingText="Signing up…"
				/>
			</Form>
		);
	}

	render() {
		return (
			<DocumentTitle title="Earth-i on AWS | Sign up">
				<BodyClassName className="bg-dark">
					<Container fluid>
						<Card className="card-register mx-auto mt-5">
							<CardHeader className="card-logo-header">
								<ReactSVG src={earthiLogo} className="card-logo" svgClassName="earthi-card-logo" />
								<ReactSVG src={awsLogo} className="card-logo" svgClassName="aws-card-logo" />
								<h1 className="sr-only">Earthi AWS Client</h1>
							</CardHeader>
							<CardBody>
								{this.state.newUser === null ? this.renderForm() : this.renderConfirmationForm()}
								<div className="text-center">
									<NavLink className="d-block small mt-3" to="/">
										Log in
									</NavLink>
								</div>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
