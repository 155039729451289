import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import menuConfig from './menuConfig';
import { TemplateUI } from 'components';
import { getAuthenticatedUserAttributes, getAuthenticatedUserGroups } from '../actions';

const securedElementGroups = [ 'basic', 'advanced' ];

export default class SideNav extends Component {
	state = {
		authGroups: [],
		showSecuredElements: false
	};

	async componentDidMount() {
		const userAttributes = await getAuthenticatedUserAttributes();
		const { authenticatedUserGroups: authGroups } = await getAuthenticatedUserGroups();

		this.setState({
			...userAttributes,
			authGroups,
			showSecuredElements:
				securedElementGroups.length === 0 || securedElementGroups.some((r) => authGroups.includes(r))
		});
	}

	buildMenuItem({ title, path = null, icon = null, groups = [], items = [] }, i, dd = false) {
		const showLink = groups.length === 0 || groups.some((r) => this.state.authGroups.includes(r));
		const childRecurse = items.length > 0;
		let itemClass = 'nav-item';
		if (path && this.props.location.pathname.indexOf(path) !== -1) {
			itemClass += ' active';
		}
		if (childRecurse) {
			itemClass += ' dropdown';
		}

		if (dd) {
			return (
				<NavLink exact key={i} className="dropdown-item" to={path}>
					{icon && <i className={'fas fa-fw mr-2 ' + icon} />}
					<span>{title}</span>
				</NavLink>
			);
		}

		i += 1;

		return (
			<li key={i} className={itemClass} title={title}>
				{showLink &&
				childRecurse && (
					<Fragment>
						<a
							className="nav-link dropdown-toggle"
							href={title}
							id={title + 'Dropdown'}
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							{icon && <i className={'fas fa-fw mr-2 ' + icon} />}
							<span>{title}</span>
						</a>
						<div className="dropdown-menu" aria-labelledby={title + 'Dropdown'}>
							{items.map((menuItem, idx) => {
								i += idx;
								return this.buildMenuItem(menuItem, i, true);
							})}
						</div>
					</Fragment>
				)}

				{showLink &&
				path && (
					<NavLink exact className="nav-link" to={path}>
						{icon && <i className={'fas fa-fw mr-2 ' + icon} />}
						<span>{title}</span>
					</NavLink>
				)}
				{!showLink && (
					<span className="nav-link disabled">
						{icon && <i className={'fas fa-fw mr-2 ' + icon} />}
						<span className="nav-link-text">{title}</span>
					</span>
				)}
			</li>
		);
	}

	buildMenuItems() {
		return menuConfig.items.map((menuItem, idx) => this.buildMenuItem(menuItem, idx, false));
	}

	render() {
		return (
			<Fragment>
				<ul className="sidebar navbar-nav">{this.buildMenuItems()}</ul>
				<TemplateUI />
			</Fragment>
		);
	}
}
