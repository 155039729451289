import { API } from 'aws-amplify';

import {
	FETCH_SERVERS,
	FETCH_SERVERS_ERR,
	START_SERVER,
	START_SERVER_ERR,
	STOP_SERVER,
	STOP_SERVER_ERR,
	REBOOT_SERVER,
	REBOOT_SERVER_ERR,
	TERMINATE_SERVER,
	TERMINATE_SERVER_ERR,
	getAuthHeaders
} from './';
import config from '../config';
import { replacer } from '../utils';

export const fetchServers = ({ regionId }) => async (dispatch) => {
	const request = API.get(
		'aws-api',
		replacer(config.apiEndpoints.servers, {
			regionId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SERVERS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SERVERS_ERR,
				payload: err
			})
	);
};

export const startServer = ({ regionId, instanceId }) => async (dispatch) => {
	const request = API.post(
		'aws-api',
		replacer(config.apiEndpoints.startServer, {
			regionId,
			instanceId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: START_SERVER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: START_SERVER_ERR,
				payload: err
			})
	);
};

export const stopServer = ({ regionId, instanceId }) => async (dispatch) => {
	const request = API.post(
		'aws-api',
		replacer(config.apiEndpoints.stopServer, {
			regionId,
			instanceId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: STOP_SERVER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: STOP_SERVER_ERR,
				payload: err
			})
	);
};

export const rebootServer = ({ regionId, instanceId }) => async (dispatch) => {
	const request = API.post(
		'aws-api',
		replacer(config.apiEndpoints.rebootServer, {
			regionId,
			instanceId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: REBOOT_SERVER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: REBOOT_SERVER_ERR,
				payload: err
			})
	);
};

export const terminateServer = ({ regionId, instanceId }) => async (dispatch) => {
	const request = API.post(
		'aws-api',
		replacer(config.apiEndpoints.terminateServer, {
			regionId,
			instanceId
		}),
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: TERMINATE_SERVER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: TERMINATE_SERVER_ERR,
				payload: err
			})
	);
};
