import React from 'react';

const startYear = 2019;
const currentYear = new Date().getFullYear();

export default () => (
	<footer className="sticky-footer">
		<div className="container my-auto">
			<div className="copyright text-center my-auto">
				Copyright<sup>©</sup> {startYear}
				{currentYear !== startYear && '-' + currentYear}{' '}
				<a href="http://earthi.space" target="_blank" rel="noopener noreferrer">
					<strong>Earth-i Ltd.</strong>
				</a>{' '}
				All Rights Reserved.
			</div>
		</div>
	</footer>
);
