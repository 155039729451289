export const FETCH_SERVERS = 'fetch_servers';
export const FETCH_SERVERS_ERR = 'fetch_servers_err';
export const START_SERVER = 'start_server';
export const START_SERVER_ERR = 'start_server_err';
export const STOP_SERVER = 'stop_server';
export const STOP_SERVER_ERR = 'stop_server_err';
export const REBOOT_SERVER = 'reboot_server';
export const REBOOT_SERVER_ERR = 'reboot_server_err';
export const TERMINATE_SERVER = 'terminate_server';
export const TERMINATE_SERVER_ERR = 'terminate_server_err';
