const menuConfig = {
	items: [
		{
			path: '/home',
			title: 'Dashboard',
			icon: 'fa-tachometer-alt',
			groups: []
		},
		{
			path: '/servers',
			title: 'Servers',
			icon: 'fa-server',
			groups: []
		}
		// 	groups: [ 'basic' ]
	]
};

export default menuConfig;
