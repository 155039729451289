import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {
	Badge,
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown
} from 'reactstrap';

import { list as regionList } from 'aws-regions';

import { fetchServers, startServer, stopServer, rebootServer, terminateServer } from 'actions';
import { Crumbs } from 'containers';

const defaultRegionId = 'eu-west-1';
const autoRefreshPeriod = 10; // seconds
const actionRefreshPeriod = 1; // seconds

class Servers extends Component {
	state = {
		stopItem: {
			name: '',
			instanceId: ''
		},
		stopModal: false,
		terminateItem: {
			name: '',
			instanceId: ''
		},
		terminateModal: false
	};

	componentDidMount() {
		const { regionId } = this.props;
		console.log('here');
		// No regionId - redirect
		if (!regionId) {
			this.props.history.push(`/servers/${defaultRegionId}`);
		} else {
			// First load of servers
			this.fetchServers();
		}

		// Auto refresh
		this.interval = setInterval(() => this.fetchServers(), autoRefreshPeriod * 1000);
	}

	componentWillUnmount() {
		// Clear the interval refresh
		clearInterval(this.interval);
	}

	// Updates from region navigation trigger a data request
	componentDidUpdate({ match: { params: { regionId: prevRegionId } } }) {
		const { regionId } = this.props;
		if (regionId && regionId !== prevRegionId) {
			this.fetchServers();
		}
	}

	fetchServers() {
		const { regionId } = this.props;
		this.props.fetchServers({
			regionId
		});
	}

	onInputSelect = async ({ target: { value } }) => {
		this.props.history.push(`/servers/${value}`);
	};

	onRefresh = () => {
		this.fetchServers();
	};

	toggleStop = () => {
		this.setState((prevState) => ({
			stopModal: !prevState.stopModal
		}));
	};

	toggleTerminate = () => {
		this.setState((prevState) => ({
			terminateModal: !prevState.terminateModal
		}));
	};

	onStart = ({ instanceId }) => (e) => {
		const { regionId } = this.props;

		this.props.startServer({
			regionId,
			instanceId
		});
		setTimeout(() => {
			this.fetchServers();
		}, actionRefreshPeriod * 1000);
	};

	onReboot = ({ instanceId }) => (e) => {
		const { regionId } = this.props;

		this.props.rebootServer({
			regionId,
			instanceId
		});
		setTimeout(() => {
			this.fetchServers();
		}, actionRefreshPeriod * 1000);
	};

	onStop = (stopItem) => async (e) => {
		await this.setState({
			stopItem,
			stopModal: true
		});
	};

	onStopAction = async () => {
		await this.setState({
			stopModal: false
		});
		const { stopItem: { instanceId } } = this.state;
		const { regionId } = this.props;
		this.props.stopServer({
			regionId,
			instanceId
		});
		setTimeout(() => {
			this.fetchServers();
		}, actionRefreshPeriod * 1000);
	};

	onTerminate = (terminateItem) => async (e) => {
		await this.setState({
			terminateItem,
			terminateModal: true
		});
	};

	// onTerminateAction = async () => {
	// 	await this.setState({
	// 		terminateModal: false
	// 	});
	// 	const { terminateItem: { instanceId } } = this.state;
	// 	const { regionId } = this.props;
	// 	// this.props.terminateServer({
	// 	// 	regionId,
	// 	//	instanceId
	// 	// });
	// 	console.log(regionId);
	// 	console.log(instanceId);
	// 	setTimeout(() => {
	// 		this.fetchServers();
	// 	}, actionRefreshPeriod * 1000);
	// };

	buildFilterForm() {
		const regions = regionList();
		return (
			<Row noGutters>
				<Col xs="6">
					<Form inline>
						<FormGroup>
							<Label for="regionId" className="align-bottom mb-3 mr-3">
								<span>Region name:</span>
								<Input
									id="regionId"
									name="regionId"
									type="select"
									className="pointered ml-3"
									value={this.props.regionId}
									onChange={this.onInputSelect}
								>
									{regions.map(({ full_name, code }, idx) => {
										return (
											<option key={`region${idx}`} value={code}>
												{full_name}
											</option>
										);
									})}
								</Input>
							</Label>
						</FormGroup>
					</Form>
				</Col>
				<Col xs="6" className="text-right">
					<Button outline className="align-bottom" onClick={this.onRefresh}>
						<i className="fas fa-sync-alt" />
					</Button>
				</Col>
			</Row>
		);
	}

	buildResultsTable() {
		const { servers = [] } = this.props;

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '45px' }}>#</th>
								<th>Name</th>
								<th>Instance ID</th>
								<th>Type</th>
								<th className="text-center" style={{ width: '120px' }}>
									AZ
								</th>
								<th className="text-center" style={{ width: '120px' }}>
									State
								</th>
								<th style={{ width: '120px' }} />
							</tr>
						</thead>
						<tbody>
							{servers.map(({ instanceName, instanceId, instanceType, az, state }, ind) => {
								const dspInd = ind + 1;
								return (
									<tr key={`server${dspInd}`}>
										<th scope="row" style={{ width: '45px' }}>
											{dspInd}
										</th>
										<td>{instanceName}</td>
										<td>{instanceId}</td>
										<td>{instanceType}</td>
										<td className="text-center" style={{ width: '120px' }}>
											{az}
										</td>
										<td className="h4 align-middle text-center" style={{ width: '120px' }}>
											{state === 'pending' && <Badge color="primary">pending</Badge>}
											{state === 'running' && <Badge color="success">running</Badge>}
											{state === 'shutting-down' && (
												<Badge color="secondary">shutting-down</Badge>
											)}
											{state === 'terminated' && <Badge color="dark">terminated</Badge>}
											{state === 'stopping' && <Badge color="info">stopping</Badge>}
											{state === 'stopped' && <Badge color="danger">stopped</Badge>}
										</td>
										<td style={{ width: '120px' }}>
											<UncontrolledDropdown>
												<DropdownToggle className="pointered" caret>
													Actions
												</DropdownToggle>
												<DropdownMenu>
													{state === 'terminated' && (
														<DropdownItem>No actions available</DropdownItem>
													)}
													{![ 'pending', 'running', 'terminated' ].includes(state) && (
														<DropdownItem
															className="pointered"
															onClick={this.onStart({ instanceId })}
														>
															<i
																className="fas fa-play mr-3"
																style={{ color: 'green' }}
															/>Start
														</DropdownItem>
													)}
													{![
														'pending',
														'shutting-down',
														'terminated',
														'stopping',
														'stopped'
													].includes(state) && (
														<Fragment>
															<DropdownItem
																className="pointered"
																onClick={this.onStop({ instanceName, instanceId })}
															>
																<i className="fas fa-stop mr-3" style={{ color: 'red' }} />Stop
															</DropdownItem>
															<DropdownItem
																className="pointered"
																onClick={this.onReboot({ instanceId })}
															>
																<i className="fas fa-redo-alt mr-3" style={{ color: 'blue' }} />Reboot
															</DropdownItem>
														</Fragment>
													)}
													{![ 'pending', 'shutting-down', 'terminated', 'stopping' ].includes(
														state
													) && (
														<DropdownItem
															className="pointered"
															onClick={this.onTerminate({ instanceName, instanceId })}
														>
															<i
																className="fas fa-skull mr-3"
																style={{ color: 'darkgray' }}
															/>Terminate
														</DropdownItem>
													)}
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const { stopItem, terminateItem } = this.state;

		return (
			<Fragment>
				<Modal isOpen={this.state.stopModal} toggle={this.toggleStop}>
					<ModalHeader toggle={this.toggleStop}>
						<i className="fas fa-stop mr-3" style={{ color: 'red' }} />Stop a server instance
					</ModalHeader>
					<ModalBody>
						Please confirm that you really want to stop the server '{stopItem.instanceName}' ({stopItem.instanceId})?
						A stopped instance can be restarted.
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.onStopAction}>
							Stop instance
						</Button>{' '}
						<Button color="secondary" onClick={this.toggleStop}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.terminateModal} toggle={this.toggleTerminate}>
					<ModalHeader toggle={this.toggleTerminate}>
						<i className="fas fa-skull mr-3" style={{ color: 'darkgray' }} />Terminate a server instance
					</ModalHeader>
					<ModalBody>
						If you REALLY, REALLY need to <strong>permanently destroy</strong> the server '{terminateItem.instanceName}'
						({terminateItem.instanceId}), please log into the AWS console and do it there!
					</ModalBody>
					<ModalFooter>
						{/* <Button color="primary" onClick={this.onTerminateAction}>
							Destroy permanently
						</Button>{' '} */}
						<Button color="secondary" onClick={this.toggleTerminate}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Earth-i on AWS | Servers">
				<Container fluid>
					<Crumbs path={[ { title: 'Servers' } ]} />
					{this.buildFilterForm()}
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ servers }, { match: { params: { regionId } } }) => {
	return {
		servers, // redux
		regionId // route url param
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchServers: (filter) => dispatch(fetchServers(filter)),
	startServer: (data) => dispatch(startServer(data)),
	stopServer: (data) => dispatch(stopServer(data)),
	rebootServer: (data) => dispatch(rebootServer(data)),
	terminateServer: (data) => dispatch(terminateServer(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Servers);
